<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: "App",
  methods: {}
};
</script>

<style lang="scss">
@import url("https://rsms.me/inter/inter.css");
@import "./assets/styles/tailwind.css";
</style>
