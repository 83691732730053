<template>
  <div>
    <div class="px-4 py-10 max-w-3xl mx-auto sm:px-6 sm:py-12 lg:max-w-4xl lg:py-16 lg:px-8 xl:max-w-6xl">
      <article class="prose lg:prose-lg mx-auto">
        <div class="flex">
          <img class="w-20 h-20 flex-shrink-0 bg-black rounded-full" style="margin-bottom: 0px;"
               src="https://pbs.twimg.com/profile_images/1299229036942512129/yql7KvWe_400x400.jpg"
               alt="Profile image">
          <p class="ml-5 pt-5">Hi! I'm Vrymel Omandam.</p>
        </div>
        <p>I've been building web applications for 9 years professionally.</p>
        <p>Currently building <a href="https://ohmystonks.vrymel.com">Oh My Stonks!</a>, a serverless web
          application using React and hosted on Netlify and AWS.</p>
        <p>Let me help you build your web application. Send me an email,
          <a href="mailto:vrymel@gmail.com">vrymel@gmail.com</a></p>

        <div>
          <div>
            <a href="mailto:vrymel@gmail.com" class="mr-4 text-gray-300">
              <font-awesome-icon icon="envelope" size="2x"></font-awesome-icon>
            </a>
            <a href="https://www.linkedin.com/in/vrymel" class="mr-4">
              <font-awesome-icon :icon="['fab', 'linkedin']" size="2x"></font-awesome-icon>
            </a>
            <a href="https://github.com/vrymel" class="mr-4">
              <font-awesome-icon :icon="['fab', 'github']" size="2x"></font-awesome-icon>
            </a>
          </div>

          <div class="mt-4">
            <a href="https://www.youracclaim.com/badges/37e40ea5-49e4-4476-ac63-588ff72a8e87/public_url">
              <img class="w-20" src="../assets/images/aws-certified-solutions-architect-associate-90x90.png">
            </a>
          </div>
        </div>
      </article>

    </div>
  </div>
</template>

<script>
export default {
  name: "home",
  components: {}
};
</script>

<style lang="scss" scoped>
</style>

